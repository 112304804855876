import React, {useState, useEffect} from "react";
import {useRecoilState, useRecoilValue} from "recoil";
import {itemAllCourseState, userState, RoadmapDetails} from "../../atom";
import {Link, useParams} from 'react-router-dom';
import i18next from "i18next";

// Import components
import Button from '../../components/Button/Button';
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import CourseDes from "../../components/CourseDes/CourseDes";
import QuestionModal from "../../components/PreviousYearQuestionModal/QuestionModal";
import Expander from "../../components/Expander/Expander";
import LoginModel from "../../components/LoginModel/LoginModel";
import ContainerLoading from "../../components/ContainerLoading/ContaineLoading";
import SkeltonHeader from "../../components/SkeltonHeader/SkeltonHeader";

// Import API functions
import {apiGetCourse, apigetMessartDetails} from "../../services/api/generalApi";
import {apiGetCourseLessons} from "../../services/api/courseDetails";

// Import assets
import img_whatsapp from "/src/assets/images/footer/whatsapp.png";

// Import course images
import ai from '../../assets/images/NewMassarsPics/ai.svg';
import coding from '../../assets/images/NewMassarsPics/coding.svg';
import electronic from '../../assets/images/NewMassarsPics/electronicAndDesign.svg';
import gameDevelopment from '../../assets/images/NewMassarsPics/gameDevelopment.svg';
import graphicDesign from '../../assets/images/NewMassarsPics/GraphicDesign.svg';
import iqTests from '../../assets/images/NewMassarsPics/iqTests.svg';
import schoolsContest from '../../assets/images/NewMassarsPics/schoolsContest.svg';
import webPath from '../../assets/images/NewMassarsPics/webPath.svg';

const getImageByRoadmapId = (id) => {
    switch (id) {
        case 1:
            return webPath;
        case 2:
            return electronic;
        case 8:
            return gameDevelopment;
        case 3:
            return iqTests;
        case 12:
            return graphicDesign;
        case 11:
            return coding;
        case 7:
            return schoolsContest;
        default:
            return ai;
    }
};

const CourseDetails = () => {
    const {id: courseId} = useParams();
    const userId = localStorage.getItem("id");
    const [data2, setData2] = useRecoilState(itemAllCourseState);
    const [messaratPackage, setMessaratPackage] = useRecoilState(RoadmapDetails);
    const [loading, setLoading] = useState(true);
    const user = useRecoilValue(userState);

    const langUpperCase = i18next.language.toUpperCase();

    const getCourseDetails = async () => {
        setLoading(true);
        try {
            const courseData = await apiGetCourse(langUpperCase, userId);
            setData2(courseData);
            const messaratData = await apigetMessartDetails(userId);
            const filteredData = messaratData.find(item => item.id == courseId);
            console.log(filteredData)

            setMessaratPackage(filteredData);

            // Fetch lessons for each course in messaratCourse
            const updatedMessaratCourse = await Promise.all(filteredData.messaratCourse.map(async (course) => {
                const lessonsData = await apiGetCourseLessons(course.courseId);
                return {
                    ...course,
                    lessons: lessonsData
                };
            }));

            setMessaratPackage(prevState => ({
                ...prevState,
                messaratCourse: updatedMessaratCourse
            }));

        } catch (error) {
            console.error("Error fetching course details:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCourseDetails();
    }, [courseId]);

    const PackagesSub = ({issub, MessarPayID, title}) => {
        const courseImage = getImageByRoadmapId(parseInt(MessarPayID));

        return (
            <div className="w-full md:w-3/12 mb-6 md:mb-0">
                <div className="bg-gray-50 p-6 rounded-2xl shadow-lg">
                    <div className="w-full mb-4">
                        <img className="object-cover w-full h-48 md:h-60 rounded-xl" src={courseImage} alt={title}/>
                    </div>
                    <h1 className="text-xl md:text-2xl text-end font-semibold tracking-wide mt-3">{messaratPackage.title}</h1>
                    <p className="text-base md:text-lg my-4 text-justify md:my-6">{messaratPackage.description}</p>
                    {issub ? (
                        <></>
                    ) : userId ? (
                        <>
                            <div className="text-center text-xl md:text-2xl font-semibold">150,000 د.ع</div>
                            <div className="w-full mt-3">
                                <Link to={`/pay/${MessarPayID}`}
                                      className="bg-custom-yellow text-zinc-900 font-semibold tracking-wide block text-center rounded-full w-full py-3">
                                    اشترك بالمسار
                                </Link>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="text-center text-xl md:text-2xl font-semi-bold">150,000 د.ع</div>
                            <div className="mt-3 w-full">
                                <a
                                    className="bg-custom-yellow cursor-pointer text-zinc-900 font-semibold tracking-wide block text-center rounded-full w-full py-3"
                                    data-bs-toggle="modal"
                                    data-bs-target="#loginModal"
                                >
                                    اشترك بالمسار
                                </a>
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    };

    if (loading) {
        return (
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-between">
                    <div className="w-full lg:w-1/4 mb-4 lg:mb-0">
                        <ContainerLoading/>
                        <div className="mt-8">
                            {[...Array(5)].map((_, index) => (
                                <SkeltonHeader key={index}/>
                            ))}
                        </div>
                    </div>
                    <div className="w-full lg:w-3/4">
                        <SkeltonHeader/>
                    </div>
                </div>
            </div>
        );
    }

    const filteredCourses = messaratPackage.messaratCourse?.filter(
        (el) => el.messaratDetailsDto.isMessar !== true
    );

    return (
        <div className="min-h-screen">
            <div className="container mx-auto px-4 py-4 md:py-8">
                <div className="flex flex-col md:flex-row md:justify-between">
                    <PackagesSub
                        issub={messaratPackage.isEnrolled}
                        MessarPayID={messaratPackage.id}
                        title={messaratPackage.description || messaratPackage.title}
                    />

                    <div className="w-full md:w-8/12">
                        <div className="mt-2">
                            <h1 className="text-xl md:text-2xl font-semibold tracking-wider mb-4">محتويات
                                المسار</h1>

                            {filteredCourses?.map((course, index) => (
                                <Expander
                                    key={index}
                                    title={course.messaratDetailsDto.title}
                                    lessons={course.lessons || []}
                                    detailsid={course.courseId}
                                    isFirstCourse={index === 0}
                                    isEnrolled={messaratPackage.isEnrolled}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <LoginModel/>

            {/*<div className="fixed bottom-4 right-4">*/}
            {/*    <a href="//api.whatsapp.com/send?phone=+9647838300650&text=لدي استفسار حول"*/}
            {/*       className="bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600 transition-duration-300">*/}
            {/*        <img src={img_whatsapp} alt="WhatsApp" className="w-12 h-12"/>*/}
            {/*    </a>*/}
            {/*</div>*/}
        </div>
    );
};

export default CourseDetails;



