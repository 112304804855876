import React from "react";


const SkeltonHeader=()=>{
    return(
        <div className="skeleton-card ">
       
        <div className="skeleton-card-body ">
          <div className="skeleton-card-title" />
      
        </div>
      </div>
    )
}
export default SkeltonHeader;